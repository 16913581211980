import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

import Icon from "components/atomic/atoms/Icon";

function getErrorMessage(name, errors) {
  const nameParts = name?.split(".") || [];
  if (nameParts?.length > 1) {
    if (nameParts.length === 2) {
      return errors[nameParts[0]]?.[nameParts[1]]?.message;
    }

    if (nameParts.length === 3) {
      const errorArray = errors[nameParts[0]] || [];
      const errorIndex = parseInt(nameParts[1]);

      return (errorArray[errorIndex] || {})[nameParts[2]]?.message;
    }
  }

  return errors[nameParts[0]]?.message;
}

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  label {
    line-height: 44px;
    width: 40%;
  }

  label + * {
    width: 60%;

    ${({ invalid }) =>
      invalid &&
      css`
        border-color: ${({ theme }) => theme.colors["cl-alert-bg"]};
      `};

    & input,
    & textarea {
      ${({ invalid }) =>
        invalid &&
        css`
          border-color: ${({ theme }) => theme.colors["cl-alert-bg"]};
        `};
    }

    & input:focus,
    & textarea:focus {
      ${({ invalid }) =>
        invalid &&
        css`
          border-width: 2px;
          border-color: ${({ theme }) => theme.colors["cl-alert-bg"]};
        `};
    }
  }

  ${({ invalid }) =>
    invalid &&
    css`
      & .react-select__control {
        border-color: ${({ theme }) => theme.colors["cl-alert-bg"]} !important;
      }

      & .react-select__control--is-focused,
      & .react-select__control:focus {
        border-width: 2px !important;
        border-color: ${({ theme }) => theme.colors["cl-alert-bg"]} !important;
      }

      & .react-async-select__control {
        border-color: ${({ theme }) => theme.colors["cl-alert-bg"]} !important;
      }

      & .react-async-select__control--is-focused,
      & .react-async-select__control:focus {
        border-width: 2px !important;
        border-color: ${({ theme }) => theme.colors["cl-alert-bg"]} !important;
      }
    `};

  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
      align-items: flex-start;
      justify-content: start;

      label {
        margin-left: 8px;
        margin-bottom: 4px;
        line-height: unset;
      }

      label,
      label + * {
        width: 100%;
      }
    `};

  ${({ column, label }) =>
    column &&
    !label &&
    css`
      > * {
        width: 100%;
      }
    `};

  ${({ column, label, invalid }) =>
    column &&
    !label &&
    invalid &&
    css`
      > * {
        border-color: ${({ theme }) => theme.colors["cl-alert-bg"]};
      }

      & input,
      & textarea {
        border-color: ${({ theme }) => theme.colors["cl-alert-bg"]};
      }

      & input:focus,
      & textarea:focus {
        border-width: 2px;
        border-color: ${({ theme }) => theme.colors["cl-alert-bg"]};
      }
    `};
`;

const Label = styled.label`
  padding-right: 16px;
  color: ${({ theme }) => theme.colors["cl-text-dark"]};
`;

const Message = styled.div`
  width: 100%;
  padding: 2px 8px;
  margin-top: 4px;
  margin-bottom: 12px;
  border-radius: 8px;
  /* background-color: ${({ theme }) => theme.colors["cl-alert-bg"]}; */
  /* border: 1px solid ${({ theme }) => theme.colors["cl-alert-bg"]}; */
  font-size: 0.75rem;
  /* color: ${({ theme }) => theme.colors["cl-alert-text"]}; */
  color: ${({ theme }) => theme.colors["cl-alert-bg"]};
  opacity: 0.9;
`;

const StyledIcon = styled(Icon)`
  display: inline;
`;

const FieldWrapper = ({ column, children, label, name, invalid, errors = {} }) => {
  const errorMessage = getErrorMessage(name, errors);

  return (
    <Row column={column} label={label} invalid={Boolean(errorMessage) || invalid}>
      {label && <Label>{label}</Label>}
      <div>
        {children}
        {errorMessage && (
          <Message>
            <StyledIcon icon="error:alert" size="24px" />
            &nbsp;
            {errorMessage}
          </Message>
        )}
      </div>
    </Row>
  );
};

FieldWrapper.propTypes = {
  column: PropTypes.bool,
  invalid: PropTypes.bool,
  children: PropTypes.node,
  label: PropTypes.node,
  name: PropTypes.string,
  errors: PropTypes.object,
};

export default FieldWrapper;

import PropTypes from "prop-types";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import styled, { css } from "styled-components";

const StyledHeader = styled(Modal.Header)`
  font-size: 1rem;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}
`;

const CancelSubscriptionConfirmModal = ({ productName, onUnsubscribe, onCancel }) => {
  return (
    <Modal centered show onHide={onCancel} size="md">
      <StyledHeader closeButton>Cancel Subscription</StyledHeader>
      <Modal.Body>Are you sure you wish to cancel your {productName} subscription?</Modal.Body>
      <Modal.Footer className="justify-content-between">
        <Button variant="secondary" onClick={onCancel}>
          Nevermind
        </Button>
        <Button variant="danger" onClick={onUnsubscribe}>
          Unsubscribe
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CancelSubscriptionConfirmModal.propTypes = {
  productName: PropTypes.string,
  onUnsubscribe: PropTypes.func,
  onCancel: PropTypes.func,
};

export default CancelSubscriptionConfirmModal;

import React, { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { EIN_TITLE_OPTIONS, MONTH_OPTIONS, SUFFIX_OPTIONS } from "_constants/variables";
import { validateTrim } from "_helpers";
import { getCustomYearOptions, getDayOptions } from "_helpers/variables";
import { selectActiveOrderDetails, selectEINRegisterProduct } from "_store/orders/selector";
import { updateProducts } from "_store/orders/slice";
import { selectDetails as selectUserDetails } from "_store/user/selector";

import Button from "components/atomic/atoms/Button";
import Icon from "components/atomic/atoms/Icon";
import CustomMaskField from "components/atomic/molecules/fields/CustomMaskField";
import SelectField from "components/atomic/molecules/fields/SelectField";
import TextField from "components/atomic/molecules/fields/TextField";

function isLeapYear(value) {
  const year = Number(value);
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

const selectStyles = {
  menuList: (base) => ({ ...base, maxHeight: "140px" }),
  option: (base) => ({
    ...base,
    padding: "4px 12px",
  }),
};

const rules = {
  firstName: () => ({
    required: "First Name is a required field",
    maxLength: {
      value: 30,
      message: "First Name should be less or equal 30 symbols",
    },
    pattern: {
      value: /^[ a-zA-Z\-&]{1,30}$/i,
      message:
        "There shouldn't be numbers here, the only punctuation and special characters allowed are hyphen (-), backslash (\\) and ampersand (&)",
    },
    validate: validateTrim,
  }),
  middleName: () => ({
    maxLength: {
      value: 30,
      message: "Middle Name should be less or equal 30 symbols",
    },
    pattern: {
      value: /^[ a-zA-Z\-&]{1,30}$/i,
      message:
        "There shouldn't be numbers here, the only punctuation and special characters allowed are hyphen (-), backslash (\\) and ampersand (&)",
    },
    validate: validateTrim,
  }),
  lastName: () => ({
    required: "Last Name is a required field",
    maxLength: {
      value: 30,
      message: "Last Name should be less or equal 30 symbols",
    },
    pattern: {
      value: /^[ a-zA-Z\-&]{1,30}$/i,
      message:
        "There shouldn't be numbers here, the only punctuation and special characters allowed are hyphen (-), backslash (\\) and ampersand (&)",
    },
    validate: validateTrim,
  }),
  suffixName: () => {},
  title: () => ({
    required: "Title Name is a required field",
  }),
  ssn: () => ({
    required: "Social Security Number is a required field",
    pattern: {
      value: /^\d{3}-\d{2}-\d{4}$/i,
      message: "Provide Social Security Number in the required format",
    },
    validate: (value) => {
      const isValid = value !== "000-00-0000" && /^\d{3}-\d{2}-\d{4}$/i.test(value);

      if (!isValid) {
        return "Provide valid Social Security Number in the required format";
      }

      return true;
    },
  }),
  closingMonth: () => ({
    required: "Closing month is a required field",
  }),
  day: (formValues) => ({
    required: "Please provide Day",
    validate: (value) => {
      if (!isLeapYear(formValues.startDateYear) && value === "29" && formValues.startDateMonth === "2") {
        return 'Incorrect date, "Feb 29" in not leap year';
      }

      return true;
    },
  }),
  month: () => ({
    required: "Please provide Month",
  }),
  year: () => ({
    required: "Please provide Year",
  }),
};

const createDate = (form, dateId) => {
  if (!form[dateId + "Year"] && form[dateId + "Year"]?.length === 0) {
    return null;
  }

  const year = parseInt(form[dateId + "Year"]);
  const month = parseInt(form[dateId + "Month"]) - 1; // to start from 0
  const day = parseInt(form[dateId + "Day"]);

  if (!Number.isNaN(new Date(year, month, day, 10, 0, 0).getTime())) {
    return new Date(year, month, day, 10, 0, 0).toISOString().split(".")[0];
  }

  return null;
};

const parseDate = (date) => {
  if (date) {
    const dateObj = new Date(date);
    const year = String(dateObj.getFullYear());
    const month = String(dateObj.getMonth() + 1);
    const day = String(dateObj.getDate());

    return [day, month, year];
  }

  return [null, null, null];
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  /* min-height: 500px; */
  padding: 8px;
  box-shadow: 0 0 3px lightgray inset;

  ${({ theme }) => css`
    background-color: ${theme.colors["cl-content-bg"]};
  `}
`;

const FieldsGroup = styled.div``;
const FieldsGroupHeader = styled.div`
  margin: 0 0 14px 8px;
`;
const FieldsGroupContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0 0 4px 8px;
  padding: 0 0 4px 8px;
  border-left: 5px solid lightgray;
`;
const FieldsGroupContentDate = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 4px;

  & > div {
    flex: 1;
  }
`;

const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
  margin-top: 24px;
`;

const StyledButton = styled(Button)`
  min-width: 112px;
  width: 200px;

  @media (max-width: 450px) {
    min-width: 80px;
    padding: 12px 4px;
  }
`;

const ButtonText = styled.span`
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  }
`;

const ActionDone = styled.div``;

const EINInfoActionForm = () => {
  const dispatch = useDispatch();

  const [needAmendment, setNeedAmendment] = useState(false);

  const userDetails = useSelector(selectUserDetails);
  const EINProduct = useSelector(selectEINRegisterProduct);
  const activeOrderDetails = useSelector(selectActiveOrderDetails);

  const EINProductOwner = EINProduct?.owner;
  const [startDay, startMonth, startYear] = parseDate(EINProduct.startDate);
  const [orderDay, orderMonth, orderYear] = parseDate(activeOrderDetails?.orderDate);

  const hasEINData =
    EINProduct?.owner?.firstName &&
    // EINProduct?.owner?.middleName &&
    EINProduct?.owner?.lastName &&
    // EINProduct?.owner?.suffixName &&
    EINProduct?.owner?.title &&
    EINProduct?.owner?.ssn &&
    EINProduct?.owner?.ssn !== "000-00-0000" &&
    EINProduct?.startDate &&
    EINProduct?.closingMonth;

  const initValues = {
    firstName: EINProductOwner.firstName || "",
    middleName: EINProductOwner.middleName || "",
    lastName: EINProductOwner.firstName || "",
    suffixName: EINProductOwner.suffixName || "",
    title: EINProductOwner.title || "",
    ssn: EINProductOwner.ssn === "000-00-0000" ? "" : EINProductOwner.ssn || "",
    closingMonth: EINProduct.closingMonth !== null ? String(EINProduct.closingMonth) : "12",
    startDateMonth: startMonth || orderMonth || "",
    startDateYear: startYear || orderYear || "",
    startDateDay: startDay || orderDay || "",
  };

  const {
    control,
    formState,
    formState: { errors },
    setValue,
    reset,
    handleSubmit,
  } = useForm({ defaultValues: initValues });

  const formValues = useWatch({ control });

  function onSubmit(values) {
    const { uid: customerId } = userDetails || {};
    const products = [
      {
        ...EINProduct,
        owner: {
          ...EINProduct.owner,
          firstName: values.firstName || null,
          middleName: values.middleName || null,
          lastName: values.lastName || null,
          suffixName: values.suffixName || null,
          title: values.title || null,
          ssn: values.ssn || null,
        },
        closingMonth: Number(values.closingMonth),
        startDate: createDate(values, "startDate"),
      },
    ];

    dispatch(
      updateProducts({
        customerId,
        products,
        redirectUrl: "../actions",
      })
    );

    return;
  }

  const clearFields = (fields, toEmpty = false) => {
    fields.forEach((field) => {
      if (toEmpty) {
        setValue(field, "");
      } else {
        setValue(field, initValues[field]);
      }
    });
  };

  const changeFields = (fields) => {
    const fieldsKeys = Object.keys(fields);

    fieldsKeys.forEach((fieldKey) => {
      setValue(fieldKey, fields[fieldKey]);
    });
  };

  return !hasEINData || needAmendment ? (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <FieldsGroup>
        <FieldsGroupHeader> Date entity was started or acquired</FieldsGroupHeader>
        <FieldsGroupContentDate>
          <SelectField
            column
            name="startDateMonth"
            placeholder="Month"
            styles={selectStyles}
            options={[{ value: "", label: "Select..." }, ...MONTH_OPTIONS]}
            control={control}
            errors={formState.errors}
            rules={rules.month()}
            clearFields={() => clearFields(["startDateDay"], true)}
          />
          <SelectField
            column
            name="startDateDay"
            placeholder="Day"
            styles={selectStyles}
            options={[
              { value: "", label: "Select..." },
              ...getDayOptions({ month: formValues.startDateMonth, year: formValues.startDateYear }),
            ]}
            control={control}
            errors={errors}
            rules={rules.day(formValues)}
          />
          <SelectField
            column
            name="startDateYear"
            placeholder="Year"
            styles={selectStyles}
            options={[
              { value: "", label: "Select..." },
              ...getCustomYearOptions({ startYear: 1990, endYear: new Date().getFullYear() }),
            ]}
            control={control}
            errors={errors}
            rules={rules.year()}
            changeFields={(value) => {
              if (!isLeapYear(value) && formValues.startDateDay === "29" && formValues.startDateMonth === "2") {
                changeFields({ startDateDay: "28" });
              }
            }}
          />
        </FieldsGroupContentDate>
      </FieldsGroup>

      <SelectField
        column
        label="Closing month of accounting year"
        placeholder="Select..."
        name="closingMonth"
        styles={selectStyles}
        options={MONTH_OPTIONS}
        control={control}
        errors={errors}
        rules={rules.closingMonth()}
      />
      <FieldsGroup>
        <FieldsGroupHeader>{"Responsible Party (Owner's) Information"}</FieldsGroupHeader>
        <FieldsGroupContent>
          <TextField
            column
            label="First Name"
            placeholder="First Name"
            name="firstName"
            control={control}
            errors={errors}
            rules={rules.firstName()}
          />
          <TextField
            column
            label="Middle Name (optional)"
            placeholder="Middle Name"
            name="middleName"
            control={control}
            errors={errors}
            rules={rules.middleName()}
          />
          <TextField
            column
            label="Last Name"
            placeholder="Last Name"
            name="lastName"
            control={control}
            errors={errors}
            rules={rules.lastName()}
          />
          <SelectField
            column
            label="Suffix Name (optional)"
            placeholder="Select..."
            name="suffixName"
            styles={selectStyles}
            options={[{ value: "", label: "Select..." }, ...SUFFIX_OPTIONS]}
            control={control}
            errors={errors}
            rules={rules.suffixName()}
          />
          <SelectField
            column
            label="Title"
            placeholder="Select..."
            name="title"
            styles={selectStyles}
            options={[{ value: "", label: "Select..." }, ...EIN_TITLE_OPTIONS]}
            control={control}
            errors={errors}
            rules={rules.title()}
          />
          <CustomMaskField
            column
            label="Social Security Number"
            placeholder="000-00-0000"
            mask="999-99-9999"
            name="ssn"
            control={control}
            errors={errors}
            rules={rules.ssn()}
          />
        </FieldsGroupContent>
      </FieldsGroup>

      <ButtonsGroup>
        {needAmendment && (
          <StyledButton
            secondary
            onClick={() => {
              reset();
              setNeedAmendment(false);
            }}
          >
            Cancel
          </StyledButton>
        )}
        <StyledButton primary>Submit</StyledButton>
      </ButtonsGroup>
    </StyledForm>
  ) : (
    <ActionDone>
      <Icon inline icon="misc:success-check" size="32px" color="#32D583" /> The action is completed, please wait for
      processing.
      <br />
      Need to make any corrections? <ButtonText onClick={() => setNeedAmendment(true)}>Click&nbsp;here</ButtonText>
    </ActionDone>
  );
};

export default EINInfoActionForm;

import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { FILE_TYPES, PRODUCT_CODES } from "_constants/products";
import { MONTH_OPTIONS } from "_constants/variables";
import { checkIfFileAllowed, getFileExtension, getFileExtensionsString } from "_helpers";
import { createDate, getCustomYearOptions, getDayOptions, isLeapYear } from "_helpers/variables";
import {
  selectActiveOrderId,
  selectBoiProduct,
  selectEINRegisterProduct,
  selectFileTypes,
} from "_store/orders/selector";
import { updateProducts } from "_store/orders/slice";
import { uploadFile } from "_store/orders/slice";
import { selectCustomerId } from "_store/user/selector";

import Button from "components/atomic/atoms/Button";
import CustomMaskField from "components/atomic/molecules/fields/CustomMaskField";
import FileField from "components/atomic/molecules/fields/FileField";
import SelectField from "components/atomic/molecules/fields/SelectField";
import TextField from "components/atomic/molecules/fields/TextField";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  /* min-height: 500px; */
  padding: 8px;
  box-shadow: 0 0 3px lightgray inset;

  ${({ theme }) => css`
    background-color: ${theme.colors["cl-content-bg"]};
  `}
`;

const FieldsGroup = styled.div``;

// const FieldsGroupHeader = styled.div`
//   text-align: center;
//   margin: 0 0 24px 0;
// `;

const FieldsGroupContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
`;

const StyledButton = styled(Button)`
  min-width: 112px;
  width: 200px;
`;

const selectStyles = {
  menuList: (base) => ({ ...base, maxHeight: "140px" }),
  option: (base) => ({
    ...base,
    padding: "4px 12px",
  }),
};

const rules = {
  ssn: () => ({
    required: "Social Security Number is a required field",
    pattern: {
      value: /^\d{3}-\d{2}-\d{4}$/i,
      message: "Provide Social Security Number in the required format",
    },
  }),
  boiIdFile: (fileType, fileExtentions) => ({
    required: "Customer ID file is not chosen",
    validate: (value) => {
      const selectedFileExt = getFileExtension(value?.name);
      const isExtensionAllowed = checkIfFileAllowed(selectedFileExt, fileType?.allowedExtensions);

      if (!isExtensionAllowed) {
        return "Please select file with correct extension: " + (fileExtentions || "any type");
      }

      if (value?.size && value?.size / 1024 / 1024 >= 3.8) {
        return "File size limit is 3.8MB";
      }

      return true;
    },
  }),
  day: (formValues) => ({
    required: "Please provide Day",
    validate: (value) => {
      if (!isLeapYear(formValues.dateOfBirthYear) && value === "29" && formValues.dateOfBirthMonth === "2") {
        return 'Incorrect date, "Feb 29" in not leap year';
      }

      return true;
    },
  }),
  month: () => ({
    required: "Please provide Month",
  }),
  year: () => ({
    required: "Please provide Year",
  }),
  driverLicenceOrID: () => ({
    required: "Driver's License / ID Number is a required field",
    validate: (value) => {
      if (value.trim().length === 0) {
        return "Driver's License / ID Number is a required field";
      }

      return true;
    },
  }),
};

const initValues = {
  dateOfBirthMonth: null,
  dateOfBirthDay: null,
  dateOfBirthYear: null,
  boiIdFile: null,
  ssn: null,
  driverLicenceOrID: null,
};

const CustomerIDNeededActionForm = () => {
  const dispatch = useDispatch();

  const form = useForm({ defaultValues: initValues });
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = form;

  const values = useWatch(form);

  const activeOrderId = useSelector(selectActiveOrderId);
  const boiProduct = useSelector(selectBoiProduct);
  const einProduct = useSelector(selectEINRegisterProduct);
  const fileTypes = useSelector(selectFileTypes);
  const customerId = useSelector(selectCustomerId);

  const orderId = boiProduct?.orderId;
  const productId = boiProduct?.id;

  const boiFileType = fileTypes?.[PRODUCT_CODES.incBoi]?.[FILE_TYPES.boiId];
  const boiFileExtensions = getFileExtensionsString(boiFileType?.allowedExtensions);

  function onSubmit() {
    const products = [
      {
        ...boiProduct,
        dateOfBirth: createDate(values, "dateOfBirth"),
        ssn: values.ssn || null,
        driverLicenceOrID: values.driverLicenceOrID || null,
      },
    ];

    dispatch(
      updateProducts({
        customerId,
        products,
        redirectUrl: "../actions",
        cb: () => {
          dispatch(
            uploadFile({
              customerId,
              orderId,
              activeOrderId,
              productId,
              fileType: FILE_TYPES.boiId,
              file: values.boiIdFile,
              successMessage: "Thank you! Customer ID successfully uploaded.",
            })
          );
        },
      })
    );
  }

  const clearFields = (fields, toEmpty = false) => {
    fields.forEach((field) => {
      if (toEmpty) {
        setValue(field, "");
      } else {
        setValue(field, initValues[field]);
      }
    });
  };

  const changeFields = (fields) => {
    const fieldsKeys = Object.keys(fields);

    fieldsKeys.forEach((fieldKey) => {
      setValue(fieldKey, fields[fieldKey]);
    });
  };

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <FieldsGroup>
          <FieldsGroupContent>
            <FileField
              column
              label="Driver's License or ID"
              name="boiIdFile"
              control={control}
              errors={errors}
              accept={boiFileExtensions}
              rules={rules.boiIdFile(boiFileType, boiFileExtensions)}
            />
            <TextField
              column
              label="Driver's License or ID Number"
              name="driverLicenceOrID"
              placeholder="Enter Number"
              control={control}
              errors={errors}
              rules={rules.driverLicenceOrID()}
            />
            <SelectField
              column
              label="Date of Birth"
              name="dateOfBirthMonth"
              placeholder="Month of Birth"
              styles={selectStyles}
              options={[{ value: "", label: "Select..." }, ...MONTH_OPTIONS]}
              control={control}
              errors={errors}
              rules={rules.month()}
              clearFields={() => clearFields(["dateOfBirthDay"], true)}
            />
            <SelectField
              column
              name="dateOfBirthDay"
              placeholder="Day of Birth"
              styles={selectStyles}
              options={[
                { value: "", label: "Select..." },
                ...getDayOptions({ month: values.dateOfBirthMonth, year: values.dateOfBirthYear }),
              ]}
              control={control}
              errors={errors}
              rules={rules.day(values)}
            />
            <SelectField
              column
              name="dateOfBirthYear"
              placeholder="Year of Birth"
              styles={selectStyles}
              options={[
                { value: "", label: "Select..." },
                ...getCustomYearOptions({ startYear: 1920, endYear: new Date().getFullYear() }),
              ]}
              control={control}
              errors={errors}
              rules={rules.year()}
              changeFields={(value) => {
                if (!isLeapYear(value) && values.dateOfBirthDay === "29" && values.dateOfBirthMonth === "2") {
                  changeFields({ dateOfBirthDay: "28" });
                }
              }}
            />
            {!einProduct && (
              <CustomMaskField
                column
                label="SSN"
                placeholder="000-00-0000"
                mask="999-99-9999"
                name="ssn"
                control={control}
                errors={errors}
                rules={rules.ssn()}
              />
            )}
            <ButtonsGroup>
              <StyledButton primary type="submit" disabled={!values.boiIdFile}>
                Send
              </StyledButton>
            </ButtonsGroup>
          </FieldsGroupContent>
        </FieldsGroup>
      </StyledForm>
    </>
  );
};

export default CustomerIDNeededActionForm;

import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";

import { config } from "_config";
import { openExternalURL } from "_helpers";
import { getStatusColor } from "_helpers/profile";
import {
  selectActionRequiredProductsCount,
  selectActiveOrderAccountManager,
  selectActiveOrderProductCodes,
  selectMembershipBlocked,
  selectProcessingStatus,
} from "_store/orders/selector";

import logo from "assets/img/logo.svg";

import Button from "components/atomic/atoms/Button";
import Icon from "components/atomic/atoms/Icon";

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 100%; */
  min-width: 260px;
  max-width: 260px;
  font-size: 1rem;
  font-weight: 400;
  overflow-y: auto;

  ${({ theme }) => css`
    background: ${theme.colors["cl-sidebar-background"]};
    color: ${theme.colors["cl-sidebar-text"]};
  `}

  @media (max-width: 991px) {
    /* display: none; */
    display: flex;

    /* animation: translateIn 0.3s; */

    ${({ theme }) => theme.animations.translateIn}

    position: absolute;
    z-index: 1033;
    min-width: 100%;
    /* width: 100vw; */
    height: 100%;
    /* margin-left: -100%; */
    /* transition: margin 0.3s; */
    transition: transform 0.3s;
    transform: translateX(-100%);

    ${({ show }) =>
      show &&
      css`
        /* display: flex; */
        /* margin-left: 0; */
        /* animation: translateOut 0.3s; */
        transform: translateX(0);
      `}
  }
`;

const SidebarLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 84px;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.875rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;

  background-color: #ffffff;
  border-bottom: 4px solid #dbe4f0;

  img {
    margin: 0 32px;
    width: 260px;

    @media (max-width: 450px) {
      margin: 0 80px;
    }
  }
`;

const SidebarNav = styled.div`
  margin-bottom: 42px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  border-bottom: 1px solid rgba(255, 255, 255, 0.175);
  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    min-width: 300px;
    max-width: 400px;
  }

  @media (max-height: 670px) {
    margin-bottom: 16px;
  }
`;

const SidebarFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  width: 100%;
  padding: 48px 0;

  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 16px 0;

    min-width: 300px;
    max-width: 400px;
  }

  @media (max-height: 670px) {
    padding: 16px 0;
  }
`;

const SupportContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* gap: 8px; */
  padding: 8px;
  margin: 16px 8px 0px;
  border: 4px solid #88eec780;
  background-color: #88eec725;
  border-radius: 12px;

  @media (max-width: 991px) {
    width: 100%;
    padding: 8px 18px 8px 26px;
  }

  @media (max-width: 400px) {
    width: 100%;
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
`;

const SupportContent = styled.div``;
const SupportRow = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
  font-size: 0.825rem;
  word-break: break-all;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};
  `}

  & a {
    text-decoration: underline;
    color: inherit;

    &:hover {
      text-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    }
  }

  &:first-child {
    font-size: 0.725rem;
  }

  @media (max-width: 991px) {
    font-size: 1rem;

    &:first-child {
      font-size: 0.825rem;
    }
  }
`;

const SidebarItem = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 40px;
  padding: 8px 18px 8px 30px;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.colors["cl-sidebar-hovered"]};
    `}
  }

  ${({ theme }) => css`
    color: ${theme.colors["cl-sidebar-text"]};
  `}

  ${({ theme, home }) =>
    home &&
    css`
      background-color: ${theme.colors["cl-sidebar-selected"]};
      color: ${theme.colors["cl-active"]};
      margin-top: 16px;
      margin-bottom: 8px;
    `}

  ${({ theme, selected }) =>
    selected &&
    css`
      background-color: ${theme.colors["cl-sidebar-selected"]};
    `}

    ${({ separate }) =>
    separate &&
    css`
      margin: 10px 0;
    `}

    @media (max-height: 670px) {
    /* height: 32px; */
    padding: 8px 18px 8px 30px;
  }

  ${({ theme, indicator }) =>
    indicator &&
    css`
      background-color: unset;
      color: ${theme.colors["cl-sidebar-indicator"]};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;

      &:hover {
        background-color: unset;
      }
    `}

  ${({ alertscount, theme }) =>
    alertscount &&
    alertscount > 0 &&
    css`
      &::after {
        content: "${alertscount > 9 ? "9+" : alertscount}";
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 10px;
        top: calc(50% - 10px);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        /* font-size: 0.8125rem; */
        font-size: 0.75rem;
        font-weight: 700;
        background-color: ${theme.colors["cl-alert-bg"]};
        color: ${theme.colors["cl-alert-text"]};
      }
    `}
`;

const SidebarItemIcon = styled(Icon)``;

const SidebarItemText = styled.div`
  flex-grow: 1;
`;

const SidebarItemIndicator = styled.div`
  display: ${({ indicator }) => (indicator ? "block" : "none")};
  height: 8px;
  width: 8px;
  border-radius: 50%;

  ${({ theme }) => css`
    background-color: ${theme.colors["cl-sidebar-indicator"]};
  `}

  ${({ indicatorColor }) =>
    indicatorColor &&
    css`
      background-color: ${indicatorColor};
    `}
`;

const CloseButtonContainer = styled.div`
  display: none;
  position: absolute;
  top: 8px;
  left: 0;
  /* width: 100%; */
  padding: 12px;
  color: #25396f;

  @media (max-width: 991px) {
    display: flex;
  }
`;

const Sidebar = ({ sidebarObj, navitems, footeritems }) => {
  const { pathname } = useLocation();

  const isMembershipBlocked = useSelector(selectMembershipBlocked);
  const productCodes = useSelector(selectActiveOrderProductCodes);
  const alertsCountActions = useSelector(selectActionRequiredProductsCount);
  const parsedLocation = pathname.slice(1).split("/");
  const currentPage = parsedLocation[parsedLocation.length - 1];
  const mainProductStatus = useSelector(selectProcessingStatus);
  const accountManager = useSelector(selectActiveOrderAccountManager);

  return (
    <SidebarContainer show={sidebarObj.show}>
      <CloseButtonContainer>
        <Icon size="32px" icon="sidebar:close" onClick={() => sidebarObj.setShow(false)} />
      </CloseButtonContainer>
      <SidebarLogo>
        {/* incorpify */}
        <img src={logo} />
      </SidebarLogo>
      <SidebarNav>
        {navitems &&
          navitems.map(({ id, icon, text, to, requiredProduct, indicator, home, separate }) => {
            let alertsCount = 0;
            if (requiredProduct && !productCodes?.includes(requiredProduct)) {
              return null;
            }

            if (id === "nav-actions") {
              alertsCount = alertsCountActions;
            }

            return (
              <SidebarItem
                disabled={isMembershipBlocked}
                alertscount={isMembershipBlocked ? 0 : alertsCount}
                key={id}
                to={to}
                home={home ? home.toString() : undefined}
                indicator={indicator}
                separate={separate}
                selected={to.includes(currentPage)}
                tabIndex={sidebarObj.show ? "1" : "-1"}
                onClick={(e) => {
                  if (isMembershipBlocked) e.preventDefault();
                  sidebarObj.setShow(false);
                }}
              >
                <SidebarItemIcon icon={icon} size="24px" />
                <SidebarItemText>{text}</SidebarItemText>
                <SidebarItemIndicator indicator={indicator} indicatorColor={getStatusColor(mainProductStatus)} />
              </SidebarItem>
            );
          })}
      </SidebarNav>
      <Button
        primary
        width="200px"
        tabIndex={sidebarObj.show ? "1" : "-1"}
        onClick={() => {
          openExternalURL(config.incorpifiedUiUrl + "/llc-incorporation-filing");
        }}
      >
        Start New Business
      </Button>
      <SidebarFooter>
        {footeritems &&
          footeritems.map(({ id, icon, text, to, indicator }) => (
            <SidebarItem
              key={id}
              to={to}
              selected={to.includes(currentPage)}
              tabIndex={sidebarObj.show ? "1" : "-1"}
              onClick={() => sidebarObj.setShow(false)}
            >
              <SidebarItemIcon icon={icon} size="24px" />
              <SidebarItemText>{text}</SidebarItemText>
              <SidebarItemIndicator indicator={indicator} />
            </SidebarItem>
          ))}
        {accountManager && (
          <SupportContainer>
            <SupportContent>
              <SupportRow>Your Dedicated Account Manager</SupportRow>
              <SupportRow>
                <Icon size="1.2rem" icon="misc:user" />
                {accountManager?.name || "—"}
              </SupportRow>
              <SupportRow>
                <Icon size="1.2rem" icon="misc:mail" />
                <a href={accountManager?.email ? `mailto:${accountManager?.email}` : "#"}>
                  {accountManager?.email || "—"}
                </a>
              </SupportRow>
              <SupportRow>
                <Icon size="1.2rem" icon="misc:phone" />
                {accountManager?.phone || "—"}
              </SupportRow>
            </SupportContent>
          </SupportContainer>
        )}
      </SidebarFooter>
    </SidebarContainer>
  );
};

Sidebar.propTypes = {
  footeritems: PropTypes.array,
  navitems: PropTypes.array,
  sidebarObj: PropTypes.shape({
    show: PropTypes.bool,
    setShow: PropTypes.func,
  }),
};

export default Sidebar;

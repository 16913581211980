import PropTypes from "prop-types";
import React from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";

const PlaceholderContainer = styled.span`
  padding: 0 1rem;
  border-radius: 4px;
  background: #eaeaea;
  opacity: 0.75;
`;

function LoadingWrapper(props) {
  const { loading, children } = props;

  if (loading) {
    return (
      <PlaceholderContainer>
        <Spinner size="sm" />
      </PlaceholderContainer>
    );
  }

  return children;
}

LoadingWrapper.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default LoadingWrapper;

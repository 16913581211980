import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { MONTH_OPTIONS } from "_constants/variables";
import { selectMainProduct } from "_store/orders/selector";
import { updateProducts } from "_store/orders/slice";
import { selectCustomerId } from "_store/user/selector";

import Button from "components/atomic/atoms/Button";
import SelectField from "components/atomic/molecules/fields/SelectField";
import TextField from "components/atomic/molecules/fields/TextField";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  /* overflow: hidden; */
  width: 100%;
  /* min-height: 500px; */
  padding: 8px;
  box-shadow: 0 0 3px lightgray inset;

  ${({ theme }) => css`
    background-color: ${theme.colors["cl-content-bg"]};
  `}
`;

const FieldsGroup = styled.div``;

const FieldsGroupHeader = styled.div`
  text-align: center;
  margin: 0 0 24px 0;
`;

const FieldsGroupContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
`;

const StyledButton = styled(Button)`
  min-width: 112px;
  width: 200px;
`;

const getYearOptions = () => {
  const startYear = new Date().getFullYear();

  return Array.from(new Array(20), (_, i) => {
    return { value: startYear + i, label: `${startYear + i}` };
  });
};

const rules = {
  annualGrossIncome: () => ({
    required: "Please select option",
  }),
  grossOver4000: () => ({
    required: "Please select option",
  }),
  locationInWV: () => ({
    required: "Please select option",
  }),
  retailProduct: () => ({
    required: "Please select option",
  }),
  businessActivity: () => ({
    required: "Please select option",
  }),
  taxableEndYearMonth: () => ({
    required: "Please select month",
  }),
  haveEmployees: () => ({
    required: "Please select option",
  }),
  countOfEmployees: () => ({
    required: "Please enter number of employees",
  }),
  payWagesInWV: () => ({
    required: "Please select option",
  }),
  onlyCasualEmployees: () => ({
    required: "Please select option",
  }),
  firstEmployeeStartWork: () => ({
    required: "Please enter date",
  }),
  firstEmployeeWasPaid: () => ({
    required: "Please enter date",
  }),
  grossWagesInWVFirstQuarter: () => ({
    required: "Please enter estimated gross wages",
  }),
  willPayrollBe1500: () => ({
    required: "Please select option",
  }),
  willEmployWVWorkersIn20Weeks: () => ({
    required: "Please select option",
  }),
  willWithholdMoreThan250Tax: () => ({
    required: "Please select option",
  }),
  earliestQuarterPayroll1500: () => ({
    required: "Please select quarter",
  }),
  earliestYearPayroll1500: () => ({
    required: "Please select year",
  }),
  earliestQuarterWorkerEmployedIn20Weeks: () => ({
    required: "Please select quarter",
  }),
  earliestYearWorkerEmployedIn20Weeks: () => ({
    required: "Please select year",
  }),
  businessSubjectToFederalTax: () => ({
    required: "Please select option",
  }),
};

const initValues = {};

const WVFormationQuestionsErrorForm = () => {
  const dispatch = useDispatch();

  const form = useForm({ defaultValues: initValues });
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = form;

  const values = useWatch(form);

  const mainProduct = useSelector(selectMainProduct);
  const customerId = useSelector(selectCustomerId);

  function onSubmit() {
    const wvFormationQuestions = Object.keys(values).reduce((res, key) => {
      res[key] = values[key];

      if (values[key] === "yes") {
        res[key] = true;
      }

      if (values[key] === "no") {
        res[key] = false;
      }

      return res;
    }, {});

    const products = [
      {
        ...mainProduct,
        wvFormationQuestions,
      },
    ];

    dispatch(
      updateProducts({
        customerId,
        products,
        redirectUrl: "../actions",
      })
    );
  }

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <FieldsGroup>
          <FieldsGroupHeader>Main Questions</FieldsGroupHeader>
          <FieldsGroupContent>
            <SelectField
              column
              label="What is your estimate of the annual gross income from this business location?"
              placeholder="Select..."
              name="annualGrossIncome"
              options={[
                { value: "", label: "Select..." },
                { value: "MV_0_20000", label: "$0 - $20,000" },
                { value: "MV_20000_MAX", label: "Over $20,000" },
              ]}
              control={control}
              errors={errors}
              rules={rules.annualGrossIncome()}
            />
            <SelectField
              column
              label="Counting all business income for the previous year at any location, has this business grossed over $4,000?"
              placeholder="Select..."
              name="grossOver4000"
              options={[
                { value: "", label: "Select..." },
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
              control={control}
              errors={errors}
              rules={rules.grossOver4000()}
            />
            <SelectField
              column
              label="Does this business have a location in WV from which you will make retail sales or do any service
              maintenance work?"
              placeholder="Select..."
              name="locationInWV"
              options={[
                { value: "", label: "Select..." },
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
              control={control}
              errors={errors}
              rules={rules.locationInWV()}
            />
            <SelectField
              column
              label="Will this business sell at either wholesale or retail, or consume for business operations any of the
              following products?"
              placeholder="Select..."
              name="retailProduct"
              options={[
                { value: "", label: "Select..." },
                { value: "ALCOHOL", label: "Alcoholic beverages" },
                { value: "TOBACCO", label: "Tobacco products" },
                { value: "MOTOR_FUEL", label: "Motor fuel" },
                { value: "SOFT_DRINKS", label: "Soft drinks" },
                { value: "NONE", label: "None of the above" },
              ]}
              control={control}
              errors={errors}
              rules={rules.retailProduct()}
            />
            <SelectField
              column
              label="Do any of these categories relate to the activity of your business?"
              placeholder="Select..."
              name="businessActivity"
              options={[
                { value: "", label: "Select..." },
                { value: "HEALTH_CARE", label: "Health care" },
                { value: "NATURAL_GAS", label: "Natural gas" },
                { value: "NATURAL_RESOURCES", label: "Natural resources" },
                { value: "PUBLIC_UTILITIES", label: "Public utilities" },
                { value: "TELECOMMUNICATION", label: "Telecommunications" },
                { value: "TRANSPORTATION", label: "Transportation" },
                { value: "NONE", label: "None of the above" },
              ]}
              control={control}
              errors={errors}
              rules={rules.businessActivity()}
            />
            <SelectField
              column
              label="What is your taxable year-end for federal tax purposes?"
              name="taxableEndYearMonth"
              placeholder="Select..."
              options={[{ value: "", label: "Select..." }, ...MONTH_OPTIONS]}
              control={control}
              errors={errors}
              rules={rules.taxableEndYearMonth()}
            />
            <SelectField
              column
              label="Do you have employees?"
              placeholder="Select..."
              name="haveEmployees"
              options={[
                { value: "", label: "Select..." },
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
              control={control}
              errors={errors}
              rules={rules.haveEmployees()}
            />
          </FieldsGroupContent>
        </FieldsGroup>
        {values.haveEmployees === "yes" && (
          <FieldsGroup>
            <FieldsGroupHeader>Employee Questions</FieldsGroupHeader>
            <FieldsGroupContent>
              <TextField
                column
                type="number"
                label="How many employees do you have working in WV?"
                placeholder="Enter Number"
                name="countOfEmployees"
                min={1}
                control={control}
                errors={errors}
                rules={rules.countOfEmployees()}
              />
              <SelectField
                column
                label="Will you pay wages to employees performing services in WV?"
                placeholder="Select..."
                name="payWagesInWV"
                options={[
                  { value: "", label: "Select..." },
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                control={control}
                errors={errors}
                rules={rules.payWagesInWV()}
              />
              <SelectField
                column
                label="Will you have only casual employees—meaning three or fewer workers working not more than 10 days in
                  any quarter?"
                placeholder="Select..."
                name="onlyCasualEmployees"
                options={[
                  { value: "", label: "Select..." },
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                control={control}
                errors={errors}
                rules={rules.onlyCasualEmployees()}
              />
              <TextField
                column
                type="date"
                label="What date did the first employee start working in WV?"
                name="firstEmployeeStartWork"
                max="2999-12-31"
                control={control}
                errors={errors}
                rules={rules.firstEmployeeStartWork()}
              />
              <TextField
                column
                type="date"
                label="Enter the date that the first employee was or will be paid:"
                name="firstEmployeeWasPaid"
                max="2999-12-31"
                control={control}
                errors={errors}
                rules={rules.firstEmployeeWasPaid()}
              />
              <TextField
                column
                type="number"
                label="What is the estimated gross wages paid in WV in the first calendar quarter of operations?"
                placeholder="Enter Number"
                name="grossWagesInWVFirstQuarter"
                min={1}
                control={control}
                errors={errors}
                rules={rules.grossWagesInWVFirstQuarter()}
              />
              <SelectField
                column
                label="Do you have or expect to have soon a quarterly payroll of $1,500 in WV?"
                placeholder="Select..."
                name="willPayrollBe1500"
                options={[
                  { value: "", label: "Select..." },
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                control={control}
                errors={errors}
                rules={rules.willPayrollBe1500()}
              />
              <SelectField
                column
                label="Will you employ at least one WV worker in 20 different calendar weeks during a calendar year?"
                placeholder="Select..."
                name="willEmployWVWorkersIn20Weeks"
                options={[
                  { value: "", label: "Select..." },
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                control={control}
                errors={errors}
                rules={rules.willEmployWVWorkersIn20Weeks()}
              />
              <SelectField
                column
                label="Will you withhold more than $250 of WV income tax per month?"
                placeholder="Select..."
                name="willWithholdMoreThan250Tax"
                options={[
                  { value: "", label: "Select..." },
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                control={control}
                errors={errors}
                rules={rules.willWithholdMoreThan250Tax()}
              />
              <SelectField
                column
                label="In what earliest quarter and year will the quarterly payroll reach or exceed $1,500? (Quarter and year needed)"
                placeholder="Select Quarter..."
                name="earliestQuarterPayroll1500"
                options={[
                  { value: "", label: "Select Quarter..." },
                  { value: 1, label: "Q1: January – March " },
                  { value: 2, label: "Q2: April – June" },
                  { value: 3, label: "Q3: July – September" },
                  { value: 4, label: "Q4: October – December" },
                ]}
                control={control}
                errors={errors}
                rules={rules.earliestQuarterPayroll1500()}
              />
              <SelectField
                column
                placeholder="Select Year..."
                name="earliestYearPayroll1500"
                options={[{ value: "", label: "Select Year..." }, ...getYearOptions()]}
                control={control}
                errors={errors}
                rules={rules.earliestYearPayroll1500()}
              />
              <SelectField
                column
                label="In what earliest quarter and year will at least one worker be employed in 20 different calendar weeks
                  during a calendar year? (Quarter and year needed)"
                placeholder="Select Quarter..."
                name="earliestQuarterWorkerEmployedIn20Weeks"
                options={[
                  { value: "", label: "Select Quarter..." },
                  { value: 1, label: "Q1: January – March " },
                  { value: 2, label: "Q2: April – June" },
                  { value: 3, label: "Q3: July – September" },
                  { value: 4, label: "Q4: October – December" },
                ]}
                control={control}
                errors={errors}
                rules={rules.earliestQuarterWorkerEmployedIn20Weeks()}
              />
              <SelectField
                column
                placeholder="Select Year..."
                name="earliestYearWorkerEmployedIn20Weeks"
                options={[{ value: "", label: "Select Year..." }, ...getYearOptions()]}
                control={control}
                errors={errors}
                rules={rules.earliestYearWorkerEmployedIn20Weeks()}
              />
              <SelectField
                column
                label="Is this business subject to federal unemployment tax?"
                placeholder="Select..."
                name="businessSubjectToFederalTax"
                options={[
                  { value: "", label: "Select..." },
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                control={control}
                errors={errors}
                rules={rules.businessSubjectToFederalTax()}
              />
            </FieldsGroupContent>
          </FieldsGroup>
        )}
        <ButtonsGroup>
          <StyledButton primary type="submit" disabled={false}>
            Send
          </StyledButton>
        </ButtonsGroup>
      </StyledForm>
    </>
  );
};

export default WVFormationQuestionsErrorForm;
